    // If you want to override variables do it here
@import 'variables';

$enable-ltr: true;
$enable-rtl: true;

// Custom Variables for styling used from here
$scrollbar-Color: rgba(200, 201, 201, 0.87);
$highlight-Color: #ed5422;
// $highlight-Color: #f8b940;
$primary-Color: #222b40;
// $yellow-Golden: #f8b940;
$yellow-Golden: #ed5422;
//Dashboard top blocks variables
$bg-Primary: #452b90;
// $bg-Primary: #452b90;
$bg-Warning: #f8b940;
// $bg-Info: #3a9b94;
$bg-Info:#17a2b8 ;
$bg-Secondary: #212121;

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';
@import 'example';

// If you want to add custom CSS you can put it here.
@import 'custom';